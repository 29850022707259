import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  *, *::before, *::after {
    box-sizing: border-box;
  }

  :root {
    --black: #121212;
    --black2: #2b2b2b;

    --white: #ffffff;
    --white2: #cccccc;
    --white3: #e5e5e5;

    --brand1: #ffde01;

    --color-header: var(--black);
    --color-text: var(--black);
    --color-footer-text: var(--white);
  }

  html {
    overflow-x: hidden;
    width: 100vw;
  }

  body {
    position: relative;
    overflow-x: hidden;
    width: 100vw;
    margin: 0;
    padding: 0;
    background: var(--white);
    color: var(--color-text);
    font-family: lato, sans-serif;
    font-weight: 400;
    font-size: 24px;
    font-weight: 500;
    line-height: 1.5;
  }

  body::-webkit-scrollbar {
    width: 8px;
  }

  html {
    scrollbar-width: thin;
    scrollbar-color: var(--brand1) var(--black2);
  }

  body::-webkit-scrollbar-track {
    background: var(--black2);
  }

  body::-webkit-scrollbar-thumb {
    background-color: var(--brand1) ;
    border-radius: 0;
    border: 0 solid var(--black2);
  }
`;
