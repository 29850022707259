import React, { useState, useEffect } from "react";
import { ThemeProvider } from "styled-components";

import "@theme/sanitize.css";
import "@theme/fonts.css";
import { mainTheme } from "@theme/theme";

import { GlobalStyle } from "./styles.js";

import { Helmet } from "react-helmet";

const Outter = ({ children }) => {
  const [pageActive, setPageActive] = useState(false);
  const [mobileMenuActive, setMobileMenuActive] = useState(false);

  useEffect(() => {
    setTimeout(() => setPageActive(true), 1000);
  });

  useEffect(() => {
    mobileMenuActive
      ? (document.querySelector("html").style.overflowY = "hidden")
      : (document.querySelector("html").style.overflowY = "visible");
  }, [mobileMenuActive]);

  return (
    <ThemeProvider theme={mainTheme}>
      <Helmet>
        <link rel="stylesheet" href="https://use.typekit.net/elg6hyx.css" />
      </Helmet>
      <GlobalStyle />
      {children}
    </ThemeProvider>
  );
};

export default Outter;
